import React, { FC, createContext, useLayoutEffect, useState } from 'react';
import { Alert, Button, Divider, Grid, IconButton, InputAdornment, InputBase, Paper, Snackbar, Menu, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear';
import AddCourse from './AddCourse';
import useUdemyCourseList from '../hooks/useUdemyCourseList';
import UdemyCourseOverView from '../layouts/UdemyCourseOverView';
import { CourseList } from '../hooks/useUdemyCourseList';
import Progress from '../core/Progress';
import { CourseCategoryApiResultBody } from '../services/CdService';

import TuneIcon from '@mui/icons-material/Tune';

interface UdemyCourseList { }

export const CourseCategoryMaster = createContext({});

const FirstList: FC<UdemyCourseList> = () => {

  console.log("rendering.....");


  const [isAddCourseOpen, setIsAddCourseOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [isAllCheck, setIsAllCheck] = useState(true);
  const [inputKeyword, setInputKeyword] = useState("");
  const { setKeyword, conditionCategory, setConditionCategory, courseCategoryCdMaster, forceRefresh, udemyCourseList, isLoading } = useUdemyCourseList("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  //検索メニューOPEN
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //検索メニューClose
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //検索メニュー条件変更
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setConditionCategory((prev: string[]) => {
      if (prev.includes(value)) {
        return prev.filter((c) => c !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  //検索メニューの全選択
  const handleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedCategories =
        courseCategoryCdMaster?.courseCategoryResponse.map(rec => rec.cd) || [];
      setConditionCategory(newSelectedCategories);
      setIsAllCheck(true)
    } else {
      setConditionCategory([]);
      setIsAllCheck(false)
    }
  }

  //コース追加モーダルを開く
  const handleAddCourseClick = () => {
    setIsAddCourseOpen(true);
  }
  //コース追加モーダルを閉じる
  const handleClose = () => {
    setIsAddCourseOpen(false);
    setInputKeyword("");
    setKeyword("");
    forceRefresh();
  }

  //スナックバーを閉じる
  const handleSnackClose = () => {
    setIsSnackOpen(false);
  }

  //検索ボタン押下時の処理
  const handleSearchClick = () => {
    setKeyword(inputKeyword);
  }

  //検索結果をclearする
  const handleClear = () => {
    setInputKeyword("");
    setKeyword("");
  }

  //この画面にアクセスされたとき最初だけ実行
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });


  //Enterで検索を走らせる
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return
    handleSearchClick();
  }

  return (

    <div>
      <Grid container sx={{ pt: 3 }} justifyContent="center" >
        <Grid item xs={12} md={6}>
          <Paper sx={{
            p: '2px 4px',
            display: 'flex',
            borderRadius: '12px',
            backgroundColor: 'white',
          }} >

            <InputBase
              sx={{ ml: 1, flex: 1, color: 'black' }}
              placeholder="コースを検索する"
              onChange={(e) => setInputKeyword(e.target.value)}
              value={inputKeyword}
              onKeyDownCapture={handleKeyDown}
              startAdornment={
                <IconButton onClick={handleMenuOpen}>
                  <TuneIcon />
                </IconButton>
              }
            />

            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem key={'all'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAll}
                      checked={isAllCheck}
                    />
                  }
                  label={"全て選択/解除"}
                />

              </MenuItem>
              <Divider />
              {courseCategoryCdMaster?.courseCategoryResponse.map((category: CourseCategoryApiResultBody) => (
                <MenuItem key={category.cd}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={conditionCategory.includes(category.cd)}
                        onChange={handleCategoryChange}
                        value={category.cd}
                      />
                    }
                    label={category.nameShort}
                  />
                </MenuItem>
              ))}
            </Menu>



            <IconButton type="button" sx={{ p: '10px' }} aria-label="clear" onClick={handleClear}>
              <ClearIcon />
            </IconButton>

            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 35, m: 1 }} orientation="vertical" />

            <Button color="primary" sx={{ borderRadius: '12px', m: 1 }} onClick={handleAddCourseClick} >コース新規</Button>
            <AddCourse open={isAddCourseOpen} handleClose={handleClose} />


          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pl: 1, pt: 3 }}    >
        {udemyCourseList?.map((course: CourseList) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={course.courseId} alignItems="center">
            <UdemyCourseOverView {...course} />
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={isSnackOpen}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          コース登録が完了しました
        </Alert>
      </Snackbar>


      <Progress isLoading={isLoading} />

    </div>


  );

}


export default FirstList;
