import { Alert, AlertTitle, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface AxiosErrorHandleProviderProps {
    children: React.ReactNode;
}

export const AxiosErrorHandleProvider: React.FC<AxiosErrorHandleProviderProps> = ({ children }) => {

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [messageDetail, setMessageDetail] = React.useState("");

    useEffect(() => {
        // axiosの共通エラーハンドリング記述
        axios.interceptors.response.use(
            (response) => {
                if (!response) { navigate('/sorry') }
                return response
            },
            (error) => {
                console.log("AxiosErrorHandlerでエラーHandling", error);

                if (error.response?.status === 401) {
                    setOpen(true);
                    setMessage("API 認証エラー");
                    // 5xx系のエラーの場合はここで処理する
                    navigate('/sorry'); // navigateを使用してリダイレクト
                    return Promise.reject(error);

                    // 4xx系のエラーの場合はここで処理する
                } else if (error.response?.status >= 400 && error.response?.status < 500) {
                    setOpen(true);
                    setMessage(error.response?.data.result);
                    setMessageDetail(error.response?.data.resultDetail);
                    return Promise.reject(error);
                } else if (axios.isCancel(error)) {
                    console.log("canecel handling");
                    return Promise.reject(error);
                } else {
                    // 5xx系のエラーの場合はここで処理する
                    navigate('/sorry'); // navigateを使用してリダイレクト
                    return Promise.reject(error);

                }
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            {children}
            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}></Snackbar> */}

            <Typography>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>

                    <Alert severity="error" onClose={handleClose}>
                        <AlertTitle>{message}</AlertTitle>
                        {messageDetail}
                    </Alert>

                </Snackbar>
            </Typography >


        </>
    );
}


