
import { Alert } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { MsgContext } from '../hooks/useMesage';

export const Notice: FC = () => {

    const [isDisplay, setIsDisplay] = useState(false);
    const {messageList,clearMsg} = useContext(MsgContext);

    //6秒後にメッセージを非表示にする
    useEffect(() => {
        console.log("useEffect.called");
        if (messageList && messageList.length > 0) {
            setIsDisplay(true);
            setTimeout(() => {
                console.log("メッセージclear処理が実行されました");
                setIsDisplay(false);
                clearMsg();
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageList]);

    return (
        <>
            {isDisplay && messageList?.map((messageInfo,index) =>
                < Alert  severity={messageInfo.severity} title={messageInfo.title} key={index}>
                    {messageInfo.message}
                </Alert >
            )}
        </>
    )

}

