import { Box, Rating } from "@mui/material";
import React, { FC } from "react";

interface CustomRatingProps {
    rating?: number;
}

const CustomRating: FC<CustomRatingProps> = (props) => {

    if (!props.rating) return (<></>);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
            <Rating name="read-only" value={props.rating} readOnly sx={{ ml: "10px" }} />
            <Box sx={{ ml: 2 }}>{props.rating}</Box>
        </Box>
    );
}

export default CustomRating;
