import { useContext, useState } from 'react';
import { ResultAddCourseApi, UdemyCourse, addUdemyCourse, getUdemyCourseInfoFromGoogleCache } from '../services/UdemyService';
import Axios from 'axios';
import { MsgContext } from './useMesage';
import { useNavigate } from 'react-router-dom';


const useUdemyCourse = (url: string) => {

    //コース情報
    const [course, setCourse] = useState<UdemyCourse>();
    const [isLoading, setIsLoading] = useState(false);

    const { addMsg } = useContext(MsgContext);

    //登録API実行した結果
    const [createCourseInfoResult, setCreateCourseInfoResult] = useState<ResultAddCourseApi>();

    const navigate = useNavigate();

    const createCourseInfo = async (url: string) => {
        try {
            setIsLoading(true);
            const courseInfo = await getUdemyCourseInfoFromGoogleCache(url);
            setCourse(courseInfo);
            return courseInfo
        } catch (error) {
            if (Axios.isAxiosError(error)) {
                console.log("AxiosError.handling...continue");
            } else {
                console.log("not AxioisError.handling...throw", error);
                navigate('/sorry'); // navigateを使用してリダイレクト
            }
        } finally {
            setIsLoading(false);
        }
    }

    const registRation = async () => {
        if (course) {
            try {
                setIsLoading(true);
                const addApiResult = await addUdemyCourse(course);
                setCreateCourseInfoResult(addApiResult);
                addMsg({ severity: "success", message: "コースを追加しました！" });
            } catch (error) {
                if (Axios.isAxiosError(error)) {
                    console.log("AxiosError.handling...continue");
                } else {
                    console.log("not AxioisError.handling...throw", error);
                    navigate('/sorry'); // navigateを使用してリダイレクト
                }
            } finally {
                setIsLoading(false);
            }
        }
    }
    return { course, setCourse,createCourseInfoResult, createCourseInfo, registRation, isLoading } as const;

}

export default useUdemyCourse;

