import React, { FC } from 'react';
import Header from './Header';
import { routes } from '../../components/core/Routes';
import { useRoutes } from 'react-router-dom';
import { AxiosErrorHandleProvider } from '../core/AxiosErrorHandleProvider';
import { Notice } from './Notice';
import { MsgContext, useMessage } from '../hooks/useMesage';


export const MainContents: FC = () => {

  const router = useRoutes(routes);

  const msgctx = useMessage();

  return (
    <>
      <div style={{ backgroundColor: '#DCDCDC', minHeight: '100vh' }}>
        <MsgContext.Provider value={msgctx}>
          <Header />
          <Notice />
          <AxiosErrorHandleProvider>
            {router}
          </AxiosErrorHandleProvider>

        </MsgContext.Provider>
      </div>
    </>
  );
}

export default MainContents;
