import { Avatar, FormControlLabel, Grid, ListItem, ListItemText, Paper, Switch, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import React, { useEffect, useState } from "react";
import { FC } from "react";
import MdWrapper from "../atom/MdWrapper";
import { Card, CardContent, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import FloatingBackButton from "../atom/FloatingBackButton";

import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CustomListItemText from "../atom/CustomListItemText";
import useGpt from "../hooks/useGpt";
import { getLoginUserName } from "../util/AxiosUtil";
import { Send } from "@mui/icons-material";

interface ChatGptProps { }

const ChatGpt: FC<ChatGptProps> = () => {

    const [isMultiLine, setIsMultiLine] = useState(false);
    const [isWelcomeMsg, setIsWelcomeMsg] = useState(true);
    const [loginUserName, setLoginUserName] = useState("");
    const { messages, isLoading, postEntrySSE, lastEntryToken, isAutoScroll, setIsAutoScroll, handleCancelPostEntrySSE } = useGpt();

    const [userInput, setUserInput] = useState<string>('');

    //チャット表示用のログイン名称取得
    useEffect(() => {
        const setStateLoginUserName = async () => {
            const cognitoUserName = await getLoginUserName();
            setLoginUserName(cognitoUserName);
        }
        setStateLoginUserName();
    }, []);


    const handleSendClick = async () => {
        setIsMultiLine(false);
        setIsWelcomeMsg(false);

        if (userInput.trim() !== '') {
            try {
                //質問送信
                await postEntrySSE(userInput);
                setUserInput('');
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && e.shiftKey) {
            // Shift + Enterが押された場合、改行を追加せずに何もしない
            setIsMultiLine(true);
        } else if (e.key === 'Enter') {
            handleSendClick();
            e.preventDefault();
            // ここでユーザーの入力を処理する関数を呼び出すなどの処理を行う
            // 例：handleSubmit(userInput);
        }
    };

    return (

        <Grid container justifyContent="center" >

            <Grid item xs={12} md={9} >

                <FloatingBackButton
                    mobileDisplayControl={true}
                    top={100}
                    left={95}
                    navigateUrl={"/"}
                />

                {/* 初期表示メッセージボード */}
                {isWelcomeMsg &&
                    <Card sx={{ mt: 5, background: "#ffffe0" }}>

                        <CardContent >

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ErrorOutlinedIcon fontSize="large" color="warning" />
                                <div style={{ marginLeft: 10, fontSize: "xx-large" }}>SPLAT-GPTへ ようこそ</div>
                            </div>
                            <div>ルールを守って正しく活用下さいね</div>

                            <ListItem divider={true} disablePadding={false}>

                                <ListItemText
                                    primary={"・顧客から貸与されている端末から、アクセスすることは禁止とします。"}
                                    primaryTypographyProps={{ variant: 'h6', color: '#505050' }}
                                />
                            </ListItem>
                            <ListItem divider={true} disablePadding={false}>
                                <ListItemText
                                    primary={"・顧客名/顧客資産（ソースコード/設計書内容など)の入力は禁止とします。"}
                                    primaryTypographyProps={{ variant: 'h6', color: '#505050' }}
                                />
                            </ListItem>
                            <ListItem divider={true} disablePadding={false}>
                                <ListItemText
                                    primary={"・予告なくサービスを一時停止/メンテンナンスをおこなう可能性があります。"}
                                    primaryTypographyProps={{ variant: 'h6', color: '#505050' }}
                                />
                            </ListItem>
                        </CardContent>
                    </Card>

                }

                {
                    !isWelcomeMsg &&
                    <>
                        {messages?.map((rec) => {
                            const isGpt = rec.role === "assistant" ? true : false;
                            const cardBackGround = isGpt ? "#f3fcfa" : "#f3fcfa";
                            return (
                                <Card sx={{ mt: 3 }}>
                                    <CardContent sx={isGpt ? { background: cardBackGround } : {}}>
                                        {isGpt ?
                                            (<div style={{ display: "flex", alignItems: "center" }}>
                                                <Avatar src={isLoading ? "./LoadingAvator.gif" : "./chatGPTLogo.png"}></Avatar>
                                                <div style={{ marginLeft: 10 }}>GPT先生</div>
                                            </div>
                                            )
                                            :
                                            (<div style={{ display: "flex", alignItems: "center" }}>
                                                <Avatar sx={{ bgcolor: green[500] }}>SKI</Avatar>
                                                <div style={{ marginLeft: 10 }}>{loginUserName}</div>
                                            </div>
                                            )
                                        }
                                        <MdWrapper mdstr={rec.content} />
                                    </CardContent>
                                </Card>
                            )

                        })}

                        <div style={{ display: "flex", alignItems: "center", padding: "8px", fontSize: "12px" }}>
                            <div>TokenInfo　</div>
                            <div>promptToken: {lastEntryToken?.prompt_tokens}　</div>
                            <div>completionToken: {lastEntryToken?.completion_tokens}　</div>
                            <div>totalToken: {lastEntryToken?.total_tokens}　</div>
                        </div>

                        <div style={{ height: "100px", }}>&nbsp;</div>
                    </>
                }
            </Grid >

            <div style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                height: isMultiLine ? "220px" : "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"

            }}>
                <Grid item xs={12} md={10} >

                    {/* キャンセルボタン(通信中のみ表示) */}
                    {isLoading &&
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="outlined" onClick={() => handleCancelPostEntrySSE()} > 生成キャンセル</Button>
                        </div>
                    }

                    {/* 自動スクロール制御スイッチ */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isAutoScroll}
                                    onChange={() => setIsAutoScroll(!isAutoScroll)}
                                />
                            }
                            label={
                                <Typography variant="body2">auto scroll</Typography>
                            }
                            labelPlacement="start" />
                    </div>


                    <Paper elevation={3} style={{ padding: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="質問を入力(Enterで送信)"
                                value={userInput}
                                rows={isMultiLine ? 5 : 1}
                                multiline={true}
                                onChange={(e) => setUserInput(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <LoadingButton
                                loading={isLoading}
                                style={{ marginLeft: '8px' }}
                                loadingPosition="center"
                                startIcon={<Send />}
                                variant="contained"
                                onClick={handleSendClick}
                            >send
                            </LoadingButton>


                        </div>
                    </Paper>
                </Grid>
            </div >
        </Grid >
    );
}


export default ChatGpt;