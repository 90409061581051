import { IconButton } from "@mui/material";
import React, { FC } from "react";
import LaunchIcon from '@mui/icons-material/Launch';

interface UdemyLinkProps {
    linkUrl: string;
}

const UdemyLinkButton: FC<UdemyLinkProps> = (props) => {

    const openUdemy = () => {
        window.open(props.linkUrl, '_blank');
    }

    return (
        <IconButton aria-label="settings" onClick={openUdemy}>
            <LaunchIcon />
        </IconButton>
    )
}

export default UdemyLinkButton;
