import React, { FC } from 'react';
import { ListItem, ListItemText, ListItemTextProps } from '@mui/material';

interface CustomListItemTextProps extends ListItemTextProps {
    primaryText: string;
    secondaryText?: any;
    disablePadding?:boolean;
}

const CustomListItemText: FC<CustomListItemTextProps> = (props) => {
    return (
        <ListItem divider={true} disablePadding={props.disablePadding}>
            <ListItemText
                primary={props.primaryText}
                secondary={props.secondaryText}
                primaryTypographyProps={{
                    fontWeight: 'bold',
                    variant: 'h6',
                }}
                secondaryTypographyProps={{
                    fontWeight: 'medium',
                    variant: 'inherit',
                    marginLeft: '10px',
                }}

                {...props}
            />
        </ListItem>
    );
};

export default CustomListItemText;
