import React, { FC, useLayoutEffect, useState } from 'react';
import SkiReview from '../layouts/SkiReview';
import { Alert, Grid, Snackbar } from '@mui/material';
import CourseDetailFabs from '../layouts/CourseDetailFabs';
import { useParams } from 'react-router-dom';
import useUdemyCourseDetail from '../hooks/useUdemyCourseDetail';
import Progress from '../core/Progress';
import UdemyCourseDetail from '../layouts/UdemyCourseDetail';
import FloatingBackButton from '../atom/FloatingBackButton';

interface UdemyCourseDetailProps { }

const CourseDetail: FC<UdemyCourseDetailProps> = (props) => {

    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const { refreshCourseDtail, udemyCourseDetail, isLoading } = useUdemyCourseDetail(useParams().courseId);

    //この画面にアクセスされたとき最初だけ実行
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    //スナックバーを閉じる
    const handleSnackClose = () => {
        setIsSnackOpen(false);
    }

    return (
        <>

            <FloatingBackButton mobileDisplayControl={true} top={80} left={115} navigateUrl={'/CourseList'} />

            <Grid container justifyContent="center" sx={{ pt: 2 }}>
                <Grid item xs={12} md={8}>
                    {udemyCourseDetail?.resultBody?.courseDetailInfo &&
                        <UdemyCourseDetail {...udemyCourseDetail.resultBody.courseDetailInfo} />
                    }
                </Grid>
            </Grid>

            {/* SKIレビュー欄 */}
            <Grid container justifyContent="center" >
                {udemyCourseDetail?.resultBody?.skiReview &&
                    udemyCourseDetail.resultBody.skiReview.map((reviewRec: any, index: number) => (
                        <Grid item xs={12} md={8} key={index}>
                            <SkiReview {...reviewRec} />
                        </Grid>
                    ))}

            </Grid>

            {//右下FABボタン部品
                udemyCourseDetail?.resultBody?.courseDetailInfo &&
                <CourseDetailFabs
                    courseId={udemyCourseDetail.resultBody.courseDetailInfo.courseId}
                    title={udemyCourseDetail.resultBody.courseDetailInfo.title}
                    linkUrl={udemyCourseDetail.resultBody.courseDetailInfo.linkUrl}
                    refreshCourseDtail={refreshCourseDtail}
                />
            }

            <Snackbar
                open={isSnackOpen}
                autoHideDuration={5000}
                onClose={handleSnackClose}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    登録処理が完了しました
                </Alert>

            </Snackbar>
            <Progress isLoading={isLoading} />

        </>
    );
}
export default CourseDetail;