import { Card, CardMedia, CardContent, Typography, CardActionArea, Divider, } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomRating from '../atom/CustomRating';

interface UdemyCourseOverViewProps {
    courseId: string;
    cardImage: string;
    title: string;
    numbSubscribers: number;
    ratings: number;
    skiRating?: number;
    skiNumSubscribers?: number;
    headline: string;
    sysCreated: string;
    courseCategoryName: string;
}


const UdemyCourseOverView: FC<UdemyCourseOverViewProps> = React.memo(props => {

    const navigate = useNavigate();

    const handleClick = (courseId: string) => {
        navigate(`/CourseDetail/${courseId}`)
    }

    return (
        <Card variant="outlined" sx={{ maxWidth: '97%' }}>
            <CardActionArea onClick={() => handleClick(props.courseId)}>
                <CardMedia
                    sx={{ height: 170 }}
                    image={props.cardImage}
                />
                <CardContent>
                    <Typography component="div" sx={{ fontSize: '20px', minHeight: '120px' }}>{props.title}</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography component="div" variant="body2" color="text.secondary">
                        <Typography component="div" sx={{ minHeight: '170px' }}>{props.headline}</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography component="div">{`Udemy情報　${props.numbSubscribers}人`}</Typography>
                        <CustomRating rating={props.ratings} />

                        <Divider sx={{ my: 1 }} />

                        <Typography component="div">{`SKI情報　　受講者数${props.skiNumSubscribers}人`}</Typography>
                        <CustomRating rating={props.skiRating} />
                        <Divider sx={{ my: 1 }} />
                        <Typography component="div">{props.courseCategoryName}</Typography>
                        <Typography component="div">コース登録日:{props.sysCreated}</Typography>
                    </Typography>
                </CardContent>

            </CardActionArea>
        </Card>
    );

})

export default UdemyCourseOverView;
