import { useContext, useEffect, useState } from "react";
import { Member, RingiAddApiParam, addRingiPL, getRingiPL } from "../services/RingiService";
import { getLoginUserId } from "../util/AxiosUtil";
import Axios from "axios";
import { MsgContext } from "./useMesage";
import { useNavigate } from "react-router-dom";

interface RingiInfo {
    courseId: string
    ringiEntryMemberId?: string
    ringiEntryMemberEmail?:string
    ringiEntryMemberName?:string
    plMemberId: string
    projectName: string
    lectureFrom: string
    lectureTo: string
    price: number
}

const useRing = (initialCourseId: string | undefined) => {

    const [courseId, setCourseId] = useState(initialCourseId);
    const [ringiSakiList, setRingiSakiList] = useState<Member[]>();
    const [isLoading, setIsLoading] = useState(false);
    const {addMsg} = useContext(MsgContext);
    const navigate = useNavigate();
    
    //稟議先取得
    useEffect(() => {
        const searchRingiSaki = async () => {
            if (courseId) {
                try {
                    setIsLoading(true);
                    const memberList = await getRingiPL();
                    setRingiSakiList(memberList);
                } catch (error) {
                    if (Axios.isAxiosError(error)) {
                        console.log("AxiosError.handling...continue");
                    } else {
                        console.log("not AxioisError.handling...throw", error);
                        navigate('/sorry'); // navigateを使用してリダイレクト
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        }
        searchRingiSaki();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    //稟議書作成
    const sendRingi = async (ringiInfo: RingiInfo) => {

        try {
            const loginUserId = await getLoginUserId();
            if (ringiInfo && courseId) {
                setIsLoading(true);
                const apiParam: RingiAddApiParam = { ...ringiInfo, ringiEntryMemberId: loginUserId };
                await addRingiPL(apiParam);
                addMsg({ severity: "success", message: "稟議書を送付しました。" });
            }
        } catch (error: any) {
            if (Axios.isAxiosError(error)) {
                console.log("AxiosError.handling...continue");
            } else {
                console.log("not AxioisError.handling...throw", error);
                navigate('/sorry'); // navigateを使用してリダイレクト
            }
        } finally {
            setIsLoading(false);
        }
    }

    return { setCourseId, ringiSakiList, sendRingi, isLoading } as const;

}


export default useRing;