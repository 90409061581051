import { createTheme } from '@mui/material/styles';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#039be5',
    },
    secondary: {
      main: '#f44336',
    },
  },
});


export default MuiTheme;
