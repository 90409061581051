import { useEffect, useState } from 'react';
import { CourseCategoryApiResultBody, getCourseCatogory } from '../services/CdService'
import { useNavigate } from 'react-router-dom';
import Axios from "axios";

const useCodemaster = () => {

console.log("debug!!!! codemaster renndering.......");

    //コースカテゴリ
    const [couseCateogry, setCourseCategory] = useState<CourseCategoryApiResultBody[]>();

    const navigate = useNavigate();

    useEffect(() => {

        console.log("debug!!!! codemaster useEffect executing...");

        //コースカテゴリ情報を取得する
        const getCouseCategoryFunc = async () => {
            try {
                const courseCategory = await getCourseCatogory();
                setCourseCategory(courseCategory.courseCategoryResponse);

            } catch (error: any) {
                if (Axios.isAxiosError(error)) {
                    console.log("AxiosError.handling...continue");
                } else {
                    console.log("not AxioisError.handling...throw", error);
                    navigate('/sorry'); // navigateを使用してリダイレクト
                }
            }

        }

        getCouseCategoryFunc();

    }, []);


    return {couseCateogry} as const;


}

export default useCodemaster;

