import axios, { AxiosResponse } from "axios";
import { createAuthHeaders, getLoginUserId } from "../util/AxiosUtil";

export interface ReviewAddApiResponse {
    result: string;
    resultDetail: string;
}

export interface ReviewAddApiParam {
    courseId: string
    memberId: string
    handle: string
    entrySyareki: number
    isUdemyCommittee:boolean
    entryRating: number
    kutikomi: string
}

export interface Member {
    courseId: string
    memberId: string
    memberName: string
    mailAddress: string
    isPL: boolean
}

//口コミ登録
export const addReview = async (ringiInfo: ReviewAddApiParam): Promise<ReviewAddApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/kutikomi/entry";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const apiResult: AxiosResponse<ReviewAddApiResponse> = await axios.post(targetUrl, ringiInfo, headers);
        return apiResult.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
        throw error;

    }

}


interface FeedBackInfo {
    courseId: string;
    entryMemberId: string;
    feedbackMemberId?: string;
    isGood: boolean;
}

export interface FeedbackResult {
    commonResult: CommonResult;
    resultBody: {
        feedBackGoodSummary: string
    }
}

interface CommonResult {
    result: string;
    resultDetail: string;
}

//いいねボタンを押した時の処理
export const feedback = async (feedbackInfo: FeedBackInfo): Promise<FeedbackResult | undefined> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/kutikomi/feedback";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const memberId = await getLoginUserId();
        const apiResult: AxiosResponse<FeedbackResult> = await axios.post(targetUrl, { ...feedbackInfo, feedbackMemberId: memberId }, headers);
        return apiResult.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
    }


}