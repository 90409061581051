import React, { useState, FC } from 'react';
import { Box, AppBar, Button, IconButton, List, ListItem, Toolbar, Typography, Grid, Paper, CardContent, MenuItem, TextField, Icon, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import CustomDialogFullScreen from '../atom/CustomDialogFullScreen';
import Rating from '@mui/material/Rating';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import useReview from '../hooks/useReview';
import Progress from '../core/Progress';
import CustomListItemText from '../atom/CustomListItemText';
import { Notice } from '../layouts/Notice';

interface CreateReviewProps {
    courseId: string,
    open: boolean,
    handleClose: () => void
}

const CreateReview: FC<CreateReviewProps> = React.memo(props => {

    const { sendReview, isLoading } = useReview(props.courseId);
    const multilineText = "１．投稿者の技術レベル\r\n２．総合評価\r\n３．コースにかかった時間\n４．どんな人におすすめ(業務をイメージして)\n５．その他\n";
    //Formの値を保持するstate
    const [formValue, setFormValue] = React.useState({
        entrySyareki: 1,
        handle: '',
        entryRating: 1,
        isUdemyCommittee: false,
        kutikomi: multilineText
    })

    //口コミ登録ボタン押下時の処理
    const handleClickSend = async () => {
        try {
            await sendReview({ ...formValue, courseId: props.courseId });
        } catch (error) {
            console.log("error", error);
        }
    }
    //文字入力の度にstate更新
    const handleChange = (e: any) => {
        const target = e.target;
        const name = target.name;
        setFormValue({ ...formValue, [name]: target.value });
    };
    const [value, setValue] = useState(1);

    //社歴年次リスト
    const nenjiArray = Array.from(new Array(40)).map((v, i) => i + 1)


    function onChangeHandle(starValue: number | null) {
        if (starValue === null) {
            setValue(1)
        } else {
            setValue(starValue)
        }
        setFormValue({ ...formValue, entryRating: starValue as number })
    }

    const handleCheckBoxChange = (e: any) => {
        setFormValue(
            {
                ...formValue,
                isUdemyCommittee: e.target.value = 'on' ? true : false
            }
        )
    }
    
    return ((
        <CustomDialogFullScreen open={props.open} onClose={props.handleClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                                口コミを登録する
                            </Typography>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            <Box sx={{ bgcolor: '#DCDCDC', height: "100%" }}>
                <Notice />
                <Grid container justifyContent="center" sx={{ pt: 2 }} spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ mb: 3 }}>
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <TextField
                                            id="entrySyareki"
                                            name="entrySyareki"
                                            select
                                            required
                                            label="社歴"
                                            defaultValue="1"
                                            InputProps={{
                                                startAdornment: (
                                                    < Icon >
                                                        <BorderColorIcon />
                                                    </Icon>
                                                )
                                            }}
                                            sx={{ width: 300 }}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="dummy" disabled>
                                                選択して下さい
                                            </MenuItem>
                                            {nenjiArray?.map((rec) => {
                                                return (
                                                    <MenuItem key="entrySyareki" value={rec}>
                                                        {rec}年目
                                                    </MenuItem>
                                                )
                                            })}

                                        </TextField>
                                        <Divider orientation="vertical" flexItem sx={{ p: 1 }} />
                                        <TextField
                                            id="handle"
                                            name="handle"
                                            label="ハンドルネーム"
                                            defaultValue=""
                                            required={true}
                                            sx={{ width: 300 }}
                                            onChange={handleChange}
                                            placeholder='本名・ハンドルネームどちらでもOKです'
                                        >
                                        </TextField>
                                    </ListItem>
                                    <ListItem>
                                        <CustomListItemText primaryText="SKI評価" disablePadding={true} secondaryText={
                                            <Rating
                                                name="entryRating"
                                                value={value}
                                                max={5}
                                                onChange={(event, starValue) => {
                                                    onChangeHandle(starValue);
                                                }}
                                            />}
                                        />
                                    </ListItem>
                                    <ListItem >
                                        <FormControlLabel control={
                                            <Checkbox
                                                id='isUdemyCommittee'
                                                name='isUdemyCommittee'
                                                onChange={handleCheckBoxChange}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Udemy委員会メンバー" />

                                    </ListItem>

                                    <ListItem >
                                        <CustomListItemText primaryText="口コミ" disablePadding={true} secondaryText={
                                            <TextField
                                                id="kutikomi"
                                                name="kutikomi"
                                                multiline
                                                rows={10}
                                                variant="outlined"
                                                defaultValue={multilineText}
                                                onChange={handleChange}
                                                sx={{ width: '95%' }}
                                            />
                                        }></CustomListItemText>
                                    </ListItem>

                                    <ListItem sx={{ fontSize: 12 }}>
                                        ※ 同一コースに対しては投稿内容で更新されます
                                    </ListItem>
                                    <ListItem sx={{ pl: 5 }}>
                                        <Button variant="contained" startIcon={<Send />} onClick={handleClickSend}>送信</Button>

                                    </ListItem>
                                    <ListItem sx={{ pl: 5 }}>

                                    </ListItem>

                                </List>

                            </CardContent>
                        </Paper>
                    </Grid>
                </Grid >
            </Box >
            <Progress isLoading={isLoading} />
        </CustomDialogFullScreen >
    ))
});

export default CreateReview;