import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Fab, useMediaQuery } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IFloatingBackButtonProps {
    mobileDisplayControl: boolean,
    top: number
    left: number
    navigateUrl: string
}

const FloatingBackButton: FC<IFloatingBackButtonProps> = (props) => {

    const isMobile: boolean = useMediaQuery("(min-width:900px)");
    const navigate = useNavigate();

    return (
        <>
            {(isMobile && props.mobileDisplayControl) &&
                <Box role="presentation" sx={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    top: props.top,
                    left: props.left,
                }}>
                    <Fab color="primary" title="戻る" onClick={() => navigate(props.navigateUrl)}><ArrowBackOutlined /></Fab>
                </Box>
            }
        </>
    )
}

export default FloatingBackButton;
