import { Slide, Dialog } from "@mui/material";
import React from "react";
import { FC } from "react";

const Transition = React.forwardRef(function Transition(
    props: any,
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomDialogFullScreenProps {
    open: boolean,
    onClose: () => void,
    children?: React.ReactNode; // 子要素を受け取る

}

const CustomDialogFullScreen: FC<CustomDialogFullScreenProps> = (props) => {

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            {props.children} {/* 子要素を出力する */}
        </Dialog>


    );
}

export default CustomDialogFullScreen;
