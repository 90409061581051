import { useContext, useState } from "react";
import { ReviewAddApiParam, addReview } from "../services/ReviewService";
import { getLoginUserId } from "../util/AxiosUtil"
import Axios from "axios";
import { MsgContext } from "./useMesage";
import { useNavigate } from "react-router-dom";

interface ReviewInfo {
    courseId: string
    memberId?: string
    handle: string
    entrySyareki: number
    isUdemyCommittee: boolean
    entryRating: number
    kutikomi: string
}

const useReview = (initialCourseId: string | undefined) => {

    const [isLoading, setIsLoading] = useState(false);
    const [courseId, setCourseId] = useState(initialCourseId);
    const navigate = useNavigate();

    const { addMsg } = useContext(MsgContext);

    //口コミ作成
    const sendReview = async (sendReviewiInfo: ReviewInfo) => {

        try {
            const loginUserId = await getLoginUserId();
            if (sendReviewiInfo && courseId) {
                setIsLoading(true);
                const apiParam: ReviewAddApiParam = { ...sendReviewiInfo, memberId: loginUserId };
                await addReview(apiParam);
                addMsg({ severity: "success", message: "口コミ登録が完了しました！ありがとうございます" });

            }
        } catch (error: any) {
            if (Axios.isAxiosError(error)) {
                console.log("AxiosError.handling...continue");
            } else {
                console.log("not AxioisError.handling...throw", error);
                navigate('/sorry'); // navigateを使用してリダイレクト
            }
        } finally {
            setIsLoading(false);
        }
    }

    return { setCourseId, sendReview, isLoading } as const;

}

export default useReview;