import { useEffect, useState } from "react";
import { CourseListApiResponse, getUdemyCourseList } from "../services/UdemyService";
import { getCourseCatogory, getCourseCategoryName, CourseCategoryApiResponse } from '../services/CdService';
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Category } from "@mui/icons-material";

export interface CourseList {
    courseId: string;
    cardImage: string;
    title: string;
    numbSubscribers: number;
    ratings: number;
    skiRating: number;
    skiNumSubscribers: number;
    contentInfoShort: string;
    level: string;
    created: string;
    headline: string;
    courseCategoryCd: string;
    courseCategoryName: string;
    sysCreated: string;
}



const useUdemyCourseList = (initailKeyword: string) => {


    const [keyword, setKeyword] = useState<string>(initailKeyword);
    const [conditionCategory, setConditionCategory] = useState<string[]>(["All"]);
    const [udemyCourseList, setUdemyCourseList] = useState<CourseList[]>();
    const [courseCategoryCdMaster, setCourseCategoryCdMaster] = useState<CourseCategoryApiResponse>();
    const [isLoading, setIsLoading] = useState(false);
    const [isforceRefresh, setIsForceRefresh] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const searchUdemyList = async () => {
            try {
                setIsLoading(true);
                const courseCategoryCdMaster = await getCourseCatogory();

                //初期値Allをマスターの値で置き換えて画面描画する
                if (conditionCategory.includes("All")) {
                    let result: string[] = [];
                    courseCategoryCdMaster.courseCategoryResponse.forEach((rec) => {
                        result.push(rec.cd);
                    })

                    //conditionCategoryのセットだけで処理を終了する
                    //UseEffectの条件でconditionCategoryの変更をwatchしてくれてるので
                    //set処理が終わると再度Useeffectが動作し下記分岐に入る
                    setConditionCategory(result);

                } else {
                    if (conditionCategory.length !== 0) {
                    const courseInfo = await getUdemyCourseList(keyword, conditionCategory);

                    //categoryをCD⇒名称に変換して配列に追加
                    const courseList: CourseList[] = courseInfo.resultBody.courseList.map((rec) => {
                        return {
                            ...rec,
                            courseCategoryName: getCourseCategoryName(courseCategoryCdMaster, rec.courseCategoryCd)
                        }
                    });
                    setUdemyCourseList(courseList);
                    setCourseCategoryCdMaster(courseCategoryCdMaster);
                }
            }

            } catch (error: any) {
            if (Axios.isAxiosError(error)) {
                console.log("AxiosError.handling...continue");
            } else {
                console.log("not AxioisError.handling...throw", error);
                navigate('/sorry'); // navigateを使用してリダイレクト
            }
        } finally {
            setIsLoading(false);
        }
    }
        //非同期処理を呼び出し 
        searchUdemyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [keyword, conditionCategory, isforceRefresh]);

const forceRefresh = () => {
    setIsForceRefresh(!isforceRefresh);
}


return { setKeyword, conditionCategory, setConditionCategory, forceRefresh, courseCategoryCdMaster, udemyCourseList, isLoading } as const;

}

export default useUdemyCourseList;




