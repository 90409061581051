import { AppBar, IconButton, Menu, MenuItem, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React, { FC } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle'
import Box from '@mui/material/Box';
import siteicon from '../../img/site-icon.png';
import siteTitleicon from '../../img/site-title.png';
import { useNavigate } from 'react-router-dom';
interface HeaderProps { }
const Header: FC<HeaderProps> = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    < Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img src={siteicon} alt="Custom Icon" style={{ marginRight: '10px', width: isSmallScreen ? '15%' : '3%' }} onClick={() => navigate('/')} />
          <img src={siteTitleicon} alt="Custom Icon" style={{ marginRight: '10px', width: isSmallScreen ? '75%' : '30%'  }} onClick={() => navigate('/')} />
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
    </Box >
  );
}
export default Header;