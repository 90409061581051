import { Box, Button, Divider, Paper, Rating, useMediaQuery, useTheme } from '@mui/material';
import React, { FC, useState } from 'react';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { feedback } from '../services/ReviewService';
import udemyIcon from '../../img/udemyiinkai-icon.png';

interface SkiReviewProps {
    courseId: string;
    handle: string;
    entryMemberId: string;
    entryRating: number;
    kutikomi: string;
    entrySyareki: string;
    isUdemyCommittee: boolean;
    feedbackGoodSumarry: string;
}


const SkiReview: FC<SkiReviewProps> = (props) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [feedbackGoodSumarry, setFeedbackGoodSumarry] = useState(props.feedbackGoodSumarry);
    const [isFeedbackDisabled, setIsFeedbackDisabled] = useState(false);

    //いいねボタン押下
    const handleFeedback = async () => {
        console.log("handleFeedback");
        const result = await feedback({
            courseId: props.courseId,
            entryMemberId: props.entryMemberId,
            feedbackMemberId: "",
            isGood: true
        })
        result?.resultBody.feedBackGoodSummary && setFeedbackGoodSumarry(result.resultBody.feedBackGoodSummary);
        setIsFeedbackDisabled(true);
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            {<Paper elevation={3} sx={{ mb: 3 }}>
                <Box display="flex" flexDirection="row" flexWrap="wrap" pl={2} pt={3}>
                    <Box flexBasis={isSmallScreen ? '25%' : '19%'} display="inline-flex">
                        <Box fontWeight="bold">社歴:</Box>
                        <Box>{props.entrySyareki}年</Box>
                    </Box>
                    <Box flexBasis={isSmallScreen ? '30%' : '20%'} display="inline-flex">
                        <Box fontWeight="bold">名前:</Box>
                        <Box>{props.handle}</Box>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap" pl={2} pt={2}>
                    <Box flexBasis={isSmallScreen ? '100%' : '30%'} display="inline-flex">
                        <Box fontWeight="bold">評価:</Box>
                        <Box>{props.entryRating}</Box>
                        <Box flexBasis={isSmallScreen ? '50%' : '20%'} display="inline-flex">
                            <Rating name="read-only" value={props.entryRating} readOnly sx={{ ml: "10px" }} />
                            {props.isUdemyCommittee &&
                                <img src={udemyIcon} alt="Custom Icon" style={{ marginLeft: '10px', width: '100%' }} />
                            }
                        </Box>

                    </Box>
                </Box>


                <Divider variant="middle" sx={{ my: 1, mt: 2 }} />
                <dl style={{ paddingLeft: 20 }}>

                    <Box display="flex" flexDirection="row" flexWrap="wrap" pt={2}>
                        <Box flexBasis={isSmallScreen ? '100%' : '40%'} display="inline-flex">
                            <Box fontWeight="bold" flexBasis={isSmallScreen ? '50%' : '20%'}>
                                口コミ
                            </Box>
                        </Box>
                    </Box>
                    <Box whiteSpace="pre-wrap" sx={{ pt: 2 }}>{props.kutikomi}</Box>
                </dl>

                <Divider variant="middle" sx={{ my: 1, mt: 2 }} />
                <Box display="flex" flexDirection="row" flexWrap="wrap" pt={1} pb={2} pl={3}>
                    <Button variant="outlined" startIcon={<ThumbUpAltIcon />} onClick={handleFeedback} disabled={isFeedbackDisabled}>
                        参考になった　{feedbackGoodSumarry}
                    </Button>
                </Box>


            </Paper>}
        </div >
    );
}


export default SkiReview;
