import {  Card, CardContent, CardHeader, CardMedia, List, ListItemText, Paper, } from '@mui/material';
import React, { FC} from 'react';

import CustomListItemText from '../atom/CustomListItemText';
import UdemyLinkButton from '../atom/UdemyLink';
import CustomRating from '../atom/CustomRating';

interface UdemyCourseDetailProps {
    courseId: string;
    title: string;
    linkUrl: string;
    price: string;
    imageUrl: string;
    headline: string;
    numSubscribers: number;
    rating: number;
    created: string;
    level: string;
    contentInfoShort: string;
    lastUpdated: string;
    courseDetail: string;
    createCacheInfo: string;
    skiRating?: number;
    skiNumSubscribers?: number;
}

const UdemyCourseDetail: FC<UdemyCourseDetailProps> = (props) => {


    const detailInfo = (detail: string) => {
        return (
            <React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: detail }}></div>
            </React.Fragment>
        )
    }

    return (
        <div style={{ backgroundColor: 'white' }}>

            <Paper elevation={3} sx={{ mb: 3 }}>

                <Card variant="outlined" title={props.title}  >

                    <CardHeader
                        title={props.title}
                        subheader={`コース所要時間　${props.contentInfoShort}`}
                        action={<UdemyLinkButton linkUrl={props.linkUrl} />}
                    />

                    <CardMedia
                        sx={{ height: 450 }}
                        image={props.imageUrl}
                    />

                    <CardContent>
                        <List>
                            <CustomListItemText primaryText="レベル" secondaryText={props.level} />
                            <CustomListItemText primaryText="作成日" secondaryText={props.created} />
                            <CustomListItemText primaryText="更新日" secondaryText={props.lastUpdated} />
                            <CustomListItemText primaryText="説明" secondaryText={props.headline} />
                            <CustomListItemText primaryText="Udemy受講者数" secondaryText={`${props.numSubscribers}人`} />
                            <CustomListItemText primaryText="Udemy評価" secondaryText={<CustomRating rating={props.rating} />} />


                            {props.skiNumSubscribers &&
                                <CustomListItemText primaryText="SKI受講者数" secondaryText={`${props.skiNumSubscribers}人`} />}
                            {props.skiRating &&
                                <CustomListItemText primaryText="SKI評価" secondaryText={<CustomRating rating={props.skiRating} />} />}

                            <CustomListItemText primaryText="コメント" secondaryText={detailInfo(props.courseDetail)} />

                            <ListItemText />{props.createCacheInfo}<ListItemText /> {/*キャッシュ作成日*/}

                        </List>

                    </CardContent>
                </Card>

            </Paper>

        </div>

    );
}

export default UdemyCourseDetail;
