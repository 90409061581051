import { createContext, useState } from "react";

interface MsgContextInterface {
    messageList: MsgInterface[]
    addMsg: (msg: MsgInterface) => void
    clearMsg: () => void
}

interface MsgInterface {
    severity: "success" | "info" | "warning" | "error" | undefined,
    title?: string,
    message: string
}

export const MsgContext = createContext<MsgContextInterface>({
    messageList: [],
    addMsg: (msg: MsgInterface) => { },
    clearMsg: () => { }
})


export const useMessage = () => {

    const [messageList, setMessageList] = useState<MsgInterface[]>([]);

    // メッセージをクリア
    const clearMsg = () => {
        setMessageList([]);
    }

    // メッセージを追加
    const addMsg = (msg: MsgInterface) => {
        setMessageList((previousMsg) => {
            return [...previousMsg, msg]; // 新しい配列を作成してメッセージを追加
        })
    }
    return { messageList, addMsg, clearMsg } as MsgContextInterface;
}

