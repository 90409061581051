import React, { FC, useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { COGNITO_ERROR } from '../constant/cognito-error-msg';
import { getCurrentUser, fetchUserAttributes, signInWithRedirect, signOut, fetchAuthSession, signIn } from 'aws-amplify/auth'

import { Hub } from 'aws-amplify/utils'

// ユーザー属性の型定義 (これは実際の属性に合わせて調整してください。ここでは email を例としています)
interface UserAttributes {
    email?: string;
}

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: '2losmjapncn43g5lt624juj220',
            userPoolId: 'ap-northeast-1_u4rJFwxW8',
            loginWith: {
                oauth: {
                    domain: 'knowledge-sharing.auth.ap-northeast-1.amazoncognito.com',
                    scopes: ['openid', 'email', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: ['https://knowledge-sharing.skig-platform.com/'],
                    redirectSignOut: ['https://knowledge-sharing.skig-platform.com/'],
                    responseType: 'code',
                }
            }
        }
    }
})


interface CustomeAuthenticatorProps {
    children?: React.ReactNode; // 子要素を受け取る
}

const CustomeAuthenticator: FC<CustomeAuthenticatorProps> = (props) => {
    const [user, setUser] = useState<any>(null); // any型は具体的なユーザーオブジェクトの型で置き換えることが望ましい
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
    const [authInProgress, setAuthInProgress] = useState<boolean>(false);

    useEffect(() => {
        async function checkUser() {

            const unsubscribe = Hub.listen("auth", (data) => {
                const { payload } = data;
                switch (payload.event) {
                    case "signedIn":
                        getUser();
                        break;
                    case "signedOut":
                        setUser(null);
                        setUserAttributes(null);
                        break;
                    default:
                        console.log(payload);
                }
            });

            await getUser();

            return () => unsubscribe();
        }

        checkUser();
    }, []);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            const currentUserAttributes: UserAttributes = await fetchUserAttributes();
            setUser(currentUser);
            setUserAttributes(currentUserAttributes);
        } catch (err) {
            console.log("Not signed in");
            setUser(null);
            setUserAttributes(null);
            await signInWithRedirect();
        }
    };

    // getToken関数の修正版
    const getToken = async () => {
        try {
            const session = await fetchAuthSession();

            // 以下で session.idToken と session.accessToken があるかチェック
            if (session) {
                const jwtIdToken = session?.tokens?.idToken; // IDトークン
                const jwtAccessToken = session?.tokens?.accessToken; // アクセストークン

                // setIdToken(jwtIdToken?.toString); // ステートにIDトークンをセット
                // setToken(jwtAccessToken?.toString); // ステートにアクセストークンをセット
            } else {
                console.log("Session not found.");
            }
        } catch (error) {
            console.error("Error getting token", error);
        }
    };
    return (
        <>
            {props.children}
        </>

    );
}
export default CustomeAuthenticator;
