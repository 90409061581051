import React from 'react';

type TextAlign = 'left' | 'right' | 'center' | 'justify';

const SorryPage = () => {
  const styles = {
    sorryPage: {
      textAlign: 'center' as TextAlign,
      margin: '50px auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      maxWidth: '400px',
      backgroundColor: '#f9f9f9',
    },
    heading: {
      color: '#e74c3c',
    },
    paragraph: {
      margin: '10px 0',
    },
  };

  return (
    <div style={styles.sorryPage}>
      <h1 style={styles.heading}>ごめんなさい</h1>
      <div style={styles.paragraph}>申し訳ございませんが、何か問題が発生した可能性があります。</div>
      <div style={styles.paragraph}>暫く経ってから再度行うか、システム管理者にお問い合わせください</div>
      <div style={styles.paragraph}>システム停止時間23:00-08:00</div>
      <div style={styles.paragraph}>hatsumi.masahiro@skig.co.jp</div>
    </div>
  );
};

export default SorryPage;
