import type { RouteObject } from 'react-router-dom'
import NotFound from '../layouts/NotFound'
import React from 'react'
import Top from '../pages/Top'
import UdemyCourseDetail from '../pages/UdemyCourseDetail'
import UdemyCourseList from '../pages/UdemyCourseList'
import SorryPage from '../pages/Sorry'
import ChatGpt from '../pages/ChatGpt'

export const routes: RouteObject[] = [
    { path: '/', element: <Top /> },
    { path: '/CourseList', element: <UdemyCourseList /> },
    { path: '/CourseDetail/:courseId', element: <UdemyCourseDetail/>},
    { path: '/sorry', element: <SorryPage />},
    { path: '/chatgpt', element: <ChatGpt />},
    { path: '*', element: < NotFound /> }
]


