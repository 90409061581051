
import { fetchAuthSession } from "aws-amplify/auth";
import { createAuthHeaders, getLoginUserId } from "../util/AxiosUtil";
import axios, { Axios, AxiosResponse } from "axios";

//メッセージ
export interface IMessage {
    role: "user" | "assistant",
    content: string
}

//API送信Param
export interface GptEntryApiParam {
    messages: IMessage[],
    userid: string
}

export interface TokenInfo {
    "prompt_tokens": number
    "completion_tokens": number,
    "total_tokens": number
}

//API送信結果
export interface GptEntryApiResponse {
    "result": string
    "message": string
    "usage": TokenInfo
}

/**
 * GPT質問送信
 * @param entry 
 * @returns 
 */
export const splatGptEntry = async (entry: IMessage[]): Promise<GptEntryApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/splatgpt/entry";

    const loginUserId = await getLoginUserId();

    const apiParam: GptEntryApiParam = {
        messages: entry,
        userid: loginUserId
    }

    //Cognito Auth 情報生成
    const headers = await createAuthHeaders();

    try {
        const apiResult: AxiosResponse<GptEntryApiResponse> = await axios.post(targetUrl, apiParam, headers);
        return apiResult.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
        throw error;
    }
}

/**
 * GPT質問送信 ServerSideEvent mode
 * 
 * @param entry 投稿質問内容(履歴込み)
 * @param onMessageCallBack チャンク受信時に呼び出すcallback処理
 * @param abortController 中断を外部から制御するためのコントロラー
 */
export const splatGptEntrySSE = async (
    entry: IMessage[], 
    onMessageCallBack: (content: string) => void, 
    abortController: AbortController): Promise<void> => {

    //通信部分
    const backEndUrl = process.env.REACT_APP_LAMBDA_POST_ENTRY_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");

    const loginUserId = await getLoginUserId();

    const apiParam: GptEntryApiParam = {
        messages: entry,
        userid: loginUserId
    }

    //Cognito Auth 情報生成
    const session = await fetchAuthSession();
    const idToken = session?.tokens?.idToken; // IDトークン

    try {
        //通信処理本体
        // axiosはストリーミングに対応していないため、fetchを使用
        const response = await fetch(backEndUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': idToken?.toString() || ""
            },
            body: JSON.stringify(apiParam),
        });
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let content = "";
        while (true) {
            const { done, value } = await reader!.read();
            if (done) {
                break;
            }
            content += decoder.decode(value, { stream: true });
            onMessageCallBack(content);
        }
        console.log("to Gpt post process is done");
    } catch (error) {
        console.log(error);
    }

}