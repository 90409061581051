import { getCurrentUser, fetchUserAttributes, fetchAuthSession, FetchUserAttributesOutput } from 'aws-amplify/auth'
  
interface Headers {
    headers: any
}

export const createAuthHeaders = async () => {
    const session = await fetchAuthSession();
    const jwtIdToken = session?.tokens?.idToken; // IDトークン
    return { headers: { "Authorization": jwtIdToken } } as Headers;
}

export const getLoginUserId = async()=>{
    const currentUser = await getCurrentUser();
    return currentUser.username;
}


export const getLoginUserName = async()=>{
    const currentUserAttributes: FetchUserAttributesOutput = await fetchUserAttributes();
    if (!currentUserAttributes || !currentUserAttributes.nickname) {
        return "";
    }
    return currentUserAttributes.nickname;
}


