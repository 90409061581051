import { useEffect, useState } from "react";
import { CourseDetailApiResponse, getUdemyCourseDetail } from "../services/UdemyService";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const useUdemyCourseDetail = (initialCourseId: string | undefined) => {

    const [courseId, setCourseId] = useState(initialCourseId);
    const [udemyCourseDetail, setUdemyCourseDetail] = useState<CourseDetailApiResponse>();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("useEffect!!");
        refreshCourseDtail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    const refreshCourseDtail = async () => {
        if (courseId) {
            try {
                setIsLoading(true);
                const courseInfo = await getUdemyCourseDetail(courseId);
                console.log("setUdemyCourseDetail from API!!" + courseId);
                setUdemyCourseDetail(courseInfo);
            } catch (error) {
                if (Axios.isAxiosError(error)) {
                    console.log("AxiosError.handling...continue");
                } else {
                    console.log("not AxioisError.handling...throw", error);
                    navigate('/sorry'); // navigateを使用してリダイレクト
                }
            } finally {
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    }

    return { courseId, setCourseId, refreshCourseDtail, udemyCourseDetail, isLoading } as const;

}

export default useUdemyCourseDetail;
