import { createAuthHeaders } from '../util/AxiosUtil';
import axios, { AxiosResponse } from 'axios';

interface CommonResult {
    result: string;
    resultDetail: string;
}

export interface CourseCategoryApiResultBody {
    cd: string;
    nameShort: string;
    nameDetail: string;
}

export interface CourseCategoryApiResponse {
    commonResult: CommonResult;
    courseCategoryResponse: CourseCategoryApiResultBody[]
}


/**
 * コースのカテゴリの一覧を取得します
 * 
 * @returns 
 */
export const getCourseCatogory = async (): Promise<CourseCategoryApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/common/cd/category";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const response: AxiosResponse<CourseCategoryApiResponse> = await axios.get(targetUrl, headers);
        return response.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
        if (error.response?.status === 400) {
            console.log("入力チェックエラーが発生しました");
            return error.response.data;
        }
        console.error("Udemyコース情報一覧取得で予期せぬエラーが発生しました");
        console.error(error);
        throw error;
    }

}


/**
 * コースカテゴリのコード値から日本語名称を返却する
 * @param cd 
 */
export const getCourseCategoryName = (courseCateogryApiResponse: CourseCategoryApiResponse, cd: string): string => {
    let result = '';
    courseCateogryApiResponse.courseCategoryResponse?.forEach((rec) => {
        if (rec.cd === cd) {
            result = rec.nameShort;
        }
    })
    return result;
}