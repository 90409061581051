import React, { FC } from 'react';
import Header from './Header';
import Top from '../pages/Top';

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => (
  <div>
    <Header /> 
    <Top />
  </div>
);

export default NotFound;
