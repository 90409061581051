import React from 'react';
import MainContents from './components/layouts/MainContents';
import { CssBaseline } from '@mui/material';
import CustomeAuthenticator from './components/core/CustomeAuthenticator';

function App() {
  return (
    <>
      <CustomeAuthenticator>
        <CssBaseline >
          <div className="App">
            <MainContents />
          </div>
        </CssBaseline>
      </CustomeAuthenticator>

    </>
  );
}

export default App;
