import { useEffect,useState } from "react";
import { IMessage, TokenInfo, splatGptEntry, splatGptEntrySSE } from "../services/GptService";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

export const useGpt = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [lastEntryToken, setLastEntryToken] = useState<TokenInfo>();
    const navigate = useNavigate();
    const [isAutoScroll, setIsAutoScroll] = useState(true);

    const [postEntrySSEController, setPostEntrySSEController] = useState(new AbortController());


    useEffect(() => {
        if (isAutoScroll === true) scrollToEnd();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages])


    //通信処理の中断
    const handleCancelPostEntrySSE = () => {
        postEntrySSEController.abort();
        setIsLoading(false);
        setPostEntrySSEController(new AbortController());
    }

    //質問投稿
    const postEntry = async (content: string) => {
        const sendMessage: IMessage[] = [
            ...messages,
            { role: "user", content: content }
        ]
        setMessages(sendMessage);
        try {
            setIsLoading(true)
            //通信処理本体
            const apiResult = await splatGptEntry(sendMessage);
            //処理結果を追加
            setMessages([
                ...sendMessage,
                { role: "assistant", content: apiResult.message }
            ]);
            //Token
            setLastEntryToken({
                prompt_tokens: apiResult.usage.prompt_tokens,
                completion_tokens: apiResult.usage.completion_tokens,
                total_tokens: apiResult.usage.total_tokens
            })
        } catch (error: any) {
            if (Axios.isAxiosError(error)) {
                console.log("AxiosError.handling...continue");
            } else {
                console.log("not AxioisError.handling...throw", error);
                navigate('/sorry'); // navigateを使用してリダイレクト
            }
        } finally {
            setIsLoading(false);
        }
    }

    //質問を投稿(SSE版) 
    //TODO サービスと処理を分割する事を考える
    const postEntrySSE = async (content: string) => {

        setIsLoading(true);

        const sendMessage: IMessage[] = [
            ...messages,
            { role: "user", content: content }
        ]

        //質問を追加
        setMessages(sendMessage);

        //チャンク受信時に動作するhandler(axiosのconfigにセットする)
        const onMessageCallBack = (content: string): void => {
            // console.log("onMessageCallBack:", content);
            if (content.indexOf("\n[METADATA]") !== -1) {

                const assistantRespons = content.split('\n[METADATA]')[0];
                try {
                    const apiFooter = JSON.parse(content.split('\n[METADATA]')[1]);

                    setLastEntryToken({
                        prompt_tokens: apiFooter?.usage.prompt_tokens,
                        completion_tokens: apiFooter?.usage.completion_tokens,
                        total_tokens: apiFooter?.usage.total_tokens
                    })
                } catch (error) {
                    console.log("token info のparseでエラー発生 setTokenをskipします");
                } finally {
                    setMessages([...sendMessage, { role: "assistant", content: assistantRespons }])
                }
            } else {
                setMessages([...sendMessage, { role: "assistant", content: content }])
            }
        }

        try {
            //gpt回答側の空メッセージを追加
            setMessages([...sendMessage, { role: "assistant", content: " " }])
            //通信処理本体            
            await splatGptEntrySSE(sendMessage, onMessageCallBack, postEntrySSEController);
            console.log("to Gpt post process is done");
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const scrollToEnd = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            // @ts-ignore [instant指定がコンパイルエラーになるため設定]
            behavior: 'instant', // smoothだとスクロールが間に合わない場合があるのでinstantを指定
        });
    }

    return { messages, isLoading, postEntry, lastEntryToken, postEntrySSE, isAutoScroll, setIsAutoScroll, handleCancelPostEntrySSE } as const;

}

export default useGpt;