import axios, { AxiosResponse } from "axios";
import { createAuthHeaders } from "../util/AxiosUtil";

export interface RingiPLApiResponse {
    commonResult: CommonResult;
    resultBody: RingiPLApiResultBody;
}

interface CommonResult {
    result: string;
    resultDetail: string;
}

interface RingiPLApiResultBody {
    memberList: Member[];
}

export interface Member {
    courseId: string
    memberId: string
    memberName: string
    mailAddress:string
    isPL: boolean
}

//稟議先取得
export const getRingiPL = async (): Promise<Member[]|undefined> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/ringi/PL";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const response: AxiosResponse<RingiPLApiResponse> = await axios.get(targetUrl, headers);
        return response.data.resultBody.memberList;

    } catch (error: any) {
        console.error("Udemyコース情報取得で予期せぬエラーが発生しました");
        console.error(error);
    }
}


export interface RingiAddApiResponse {
    commonResult: CommonResult;
}

export interface RingiAddApiParam{
    courseId:string
    ringiEntryMemberId:string
    plMemberId:string
    projectName:string
    lectureFrom:string
    lectureTo:string
    price:number
}

//稟議登録
export const addRingiPL = async (ringiInfo: RingiAddApiParam): Promise<RingiAddApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/ringi/entry";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const apiResult:AxiosResponse<RingiAddApiResponse> = await axios.post(targetUrl, ringiInfo, headers);
        return apiResult.data;

    } catch (error: any) {
        // console.error(JSON.stringify(error));
        // if (error.status === 400) {
        //     console.log("入力チェックエラーが発生しました");
        //     return error.response.data;
        // }
        // console.error("Udemyコース登録で予期せぬエラーが発生しました");
        console.error(error);
        throw error;
    }


}