import React, { FC, useState } from 'react';
import { AttachEmail, Edit } from '@mui/icons-material';
import { Zoom, Box, Fab } from '@mui/material';
import CreateRingi from '../pages/CreateRingi';
import CreateReview from '../pages/CreateReview';

interface CourseDetailFabsProps { 
    courseId:string,
    title:string
    linkUrl:string,
    refreshCourseDtail: () => void;
}

const CourseDetailFabs: FC<CourseDetailFabsProps> = (props) => {

    const [isRingiOpen ,setIsRingiOpen] = useState(false);
    const [isReviewOpen ,setIsReviewOpen] = useState(false);

    //稟議書申請ボタンを押したときの処理
    const handleRingiClick = () => {
        setIsRingiOpen(true);
    }

    const handleClose = () => {
        setIsRingiOpen(false);
    }

    //口コミ登録ボタンを押したときの処理
    const handleReviewClick = async () => {
        setIsReviewOpen(true);
    }

    const handleReviewClose = async () => {
        await props.refreshCourseDtail();
        setIsReviewOpen(false);
    }

    return (

        <Zoom in={true} mountOnEnter unmountOnExit style={{ transitionDelay: "500ms" }}>
            <Box role="presentation" sx={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                bottom: 18, right: 120,
                "@media (max-width: 600px)": {
                    bottom: 40, right: 16,
                }

            }}>
                <Fab color="primary" title="口コミを登録する" onClick={handleReviewClick}><Edit /></Fab>
                <CreateReview courseId={props.courseId} open={isReviewOpen} handleClose={handleReviewClose} />

                <Fab color="primary" title="稟議書申請" onClick= {handleRingiClick}><AttachEmail /></Fab>
                <CreateRingi courseId={props.courseId} title={props.title} linkUrl ={props.linkUrl} open={isRingiOpen} handleClose={handleClose} />

            </Box>
        </Zoom>
    );
}
export default CourseDetailFabs;
