import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import eyecatchGaibukenshu from '../../img/eye-catch-gaibukenshu.png';
import eyecatchSplatblog from '../../img/eye-catch-splatblog.png'
import eyecatchOpenAi from '../../img/eye-catch-openai.png'

interface TopProps { }

const Top: FC<TopProps> = () => {

  const navigate = useNavigate();

  const handleUdemyClick = () => {
    if (process.env.REACT_APP_IS_MAINTE === "true") {
      const input = window.prompt('ただいまUdemyメンテナンス中です。少々お待ちください。');
      if (input === 'admin') {
        navigate('/CourseList');
      }
    } else {
      navigate('/CourseList');
    }
  }

  return (

    <div style={{
      //border: '1px solid red',
      background: 'linear-gradient(to bottom, #039be5 0%, #039be5 40%, #DCDCDC 40%, #DCDCDC 100%)',
      height: '90vh',
    }}>


      <Grid container justifyContent="center">
        <Grid item xs={12} xl={10}>

          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 5 }} spacing={{ xs: 1, sm: 2, md: 3, xl: 5 }} sx={{ pl: 1, pt: 5 }} justifyContent="space-around">

            <Grid item xs={12} sm={6} md={4} >
              <Card variant="outlined" >
                <CardActionArea onClick={() => navigate('/ChatGpt')}>
                  <CardMedia
                    // sx={{ height: 450 }}
                    sx={{ height: '50vh' }}
                    image={eyecatchOpenAi}

                  />
                  <CardContent>
                    <Divider sx={{ my: 1 }} />
                    <Typography component="div" variant="body2" color="text.secondary">
                      <Typography component="div" sx={{ minHeight: '100px' }}>業務効率化/品質向上に関する検証/案出し、社内勉強会/自己学習等でご利用ください。<br /> <b>普通に利用すると有料なVer4.0を利用できます</b></Typography>
                    </Typography>
                  </CardContent>

                </CardActionArea>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} md={4} >
              <Card variant="outlined" >
                <CardActionArea onClick={handleUdemyClick}>
                  <CardMedia
                    sx={{ height: '50vh' }}
                    image={eyecatchGaibukenshu}
                  />
                  <CardContent>
                    <Divider sx={{ my: 1 }} />
                    <Typography component="div" variant="body2" color="text.secondary">
                      <Typography component="div" sx={{ minHeight: '100px' }}>SKI社員が受講した内容や結果を共有する口コミサイトです。皆さんと育てて行きたいです。よろしくお願いします</Typography>
                    </Typography>
                  </CardContent>

                </CardActionArea>
              </Card>
            </Grid>



            <Grid item xs={12} sm={6} md={4} >
              <Card variant="outlined" >
                <CardActionArea onClick={() => window.open("https://info.skig-platform.com/", '_blank')}>
                  <CardMedia
                    sx={{ height: '50vh' }}
                    image={eyecatchSplatblog}
                  />
                  <CardContent>
                    <Divider sx={{ my: 1 }} />
                    <Typography component="div" variant="body2" color="text.secondary">
                      <Typography component="div" sx={{ minHeight: '100px' }}>SKIの社内ブログ。技術系じゃないネタも投稿されてます。</Typography>
                    </Typography>
                  </CardContent>

                </CardActionArea>
              </Card>
            </Grid>
          </Grid >
        </Grid>
      </Grid>






    </div >
  );
}
export default Top;
