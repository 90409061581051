
import { createAuthHeaders, getLoginUserId } from '../util/AxiosUtil';
import axios, { AxiosResponse } from 'axios';

export interface UdemyCourse {
    courseId: string,
    title: string,
    linkUrl: string,
    price: string,
    imageUrl: string,
    headline: string,
    numSubscribers: number,
    rating: number,
    created: string,
    level: string,
    contentInfoShort: string,
    lastUpdated: string,
    courseDetail: string,
    createCacheInfo: string,
    courseCategoryCd: string
}

//コース情報取得From GoogleCacheバージョン
export const getUdemyCourseInfoFromGoogleCache = async (url: string): Promise<UdemyCourse | undefined> => {

    if (!url) throw new Error("urlが指定されていません");

    const apigatewayUrl = process.env.REACT_APP_AWS_APIGATEWAY_URL;
    if (!apigatewayUrl) throw new Error("環境変数にREACT_APP_AWS_APIGATEWAY_URLが設定されていません");

    const targetUrl = apigatewayUrl + "/udemyCourseInfo";
    const headers = await createAuthHeaders();
    try {
        const udemyCourseInfo: AxiosResponse<UdemyCourse> = await axios.get(targetUrl, { params: { url: url, ...headers } });
        return udemyCourseInfo.data as UdemyCourse;
    } catch (error: unknown) {
        console.error(error);
        //throw error;
    }

}


export interface ResultAddCourseApi {
    commonResult: {
        "result": string,
        "resultDetail": string
    }
}

//コース登録APIの呼び出し
export const addUdemyCourse = async (udemyCourseInfo: UdemyCourse): Promise<ResultAddCourseApi | undefined> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/entry";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const memberId = await getLoginUserId();
        console.log(memberId);
        const result: AxiosResponse<ResultAddCourseApi> = await axios.post(targetUrl, { ...udemyCourseInfo, memberId: memberId }, headers);
        return result.data;
    } catch (error: any) {
        console.error(JSON.stringify(error));
        throw error;
    }
}


interface CommonResult {
    result: string;
    resultDetail: string;
}

export interface Course {
    courseId: string;
    cardImage: string;
    title: string;
    numbSubscribers: number;
    ratings: number;
    skiRating: number;
    skiNumSubscribers: number;
    contentInfoShort: string;
    level: string;
    created: string;
    headline: string;
    sysCreated: string;
    courseCategoryCd: string;
}

interface ApiResponseListResultBody {
    courseList: Course[];
}

export interface CourseListApiResponse {
    commonResult: CommonResult;
    resultBody: ApiResponseListResultBody;
}

//コース一覧取得APIの呼び出し
export const getUdemyCourseList = async (keyword: string, category: string[]): Promise<CourseListApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/list";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const response: AxiosResponse<CourseListApiResponse> = await axios.get(targetUrl, { params: { limit: 100, keyword: keyword, category: category.join(',') }, ...headers });
        return response.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
        if (error.response?.status === 400) {
            console.log("入力チェックエラーが発生しました");
            return error.response.data;
        }
        console.error("Udemyコース情報一覧取得で予期せぬエラーが発生しました");
        console.error(error);
        throw error;
    }
}

interface courseDetailInfo {
    courseId: string;
    memberId: string;
    title: string;
    linkUrl: string;
    price: string;
    imageUrl: string;
    headline: string;
    numSubscribers: number;
    rating: number;
    created: string;
    level: string;
    contentInfoShort: string;
    lastUpdated: string;
    courseDetail: string;
    skiRating: number;
    skiNumSubscribers: number;
    createCacheInfo: string;
    sysCreated: string;
}

interface skiReview {
    courseId: string;
    name: string;
    handle: string;
    entryMemberId: string;
    entryRating: number
    kutikomi: string
    entrySyareki: string
    isUdemyCommittee: boolean
    feedbackGoodSumarry: string
}

interface ApiResponseDetailResultBody {
    courseDetailInfo: courseDetailInfo;
    skiReview: skiReview[];
}

export interface CourseDetailApiResponse {
    commonResult: CommonResult;
    resultBody: ApiResponseDetailResultBody;
}

//コース取得APIの呼び出し
export const getUdemyCourseDetail = async (courseId: string): Promise<CourseDetailApiResponse> => {

    const backEndUrl = process.env.REACT_APP_BACKEND_URL;
    if (!backEndUrl) throw new Error("環境変数にREACT_APP_BACKEND_URLが設定されていません");
    const targetUrl = backEndUrl + "/udemy/course/detail";

    try {
        //Cognito Auth 情報生成
        const headers = await createAuthHeaders();
        const response = await axios.get(targetUrl, { params: { courseId: courseId }, ...headers });
        return response.data;

    } catch (error: any) {
        console.error(JSON.stringify(error));
        if (error.response?.status === 400) {
            console.log("入力チェックエラーが発生しました");
            return error.response.data;
        }
        console.error("Udemyコース情報取得で予期せぬエラーが発生しました");
        console.error(error);
        throw error;
    }

}

