import React, { FC, useState } from 'react';
import { Box, AppBar, Button, IconButton, List, ListItem, Toolbar, Typography, Grid, Paper, CardContent, Divider, InputBase, Select, MenuItem, SelectChangeEvent, InputLabel, FormControl } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import 'dayjs/locale/ja'
import CustomDialogFullScreen from '../atom/CustomDialogFullScreen';
import useUdemyCourse from '../hooks/useUdemyCourse';
import UdemyCourseDetail from '../layouts/UdemyCourseDetail';
import Progress from '../core/Progress';
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear';
import { Notice } from '../layouts/Notice';
import useCodemaster from '../hooks/useCodemaster';


interface AddCourseProps {
    open: boolean,
    handleClose: () => void
}

const AddCourse: FC<AddCourseProps> = React.memo(props => {

    const [inputUrl, setInputUrl] = useState("")
    const { course, setCourse, createCourseInfo, registRation, isLoading } = useUdemyCourse(inputUrl);
    const [isDisableRegBtn, setIsDisableRegBtn] = useState(true); //登録ボタンの活性/非活性
    const { couseCateogry } = useCodemaster();

    //検索ボタン押下時の処理
    const handleClickSearch = async () => {
        if (inputUrl) {
            //ここにチェック処理を入れてUdemyコース型であることをチェックする
            await createCourseInfo(inputUrl);
            setIsDisableRegBtn(false);
        }
    }

    //登録ボタン押下時の処理
    const handleClickAdd = async () => {
        try {
            await registRation();
        } catch (error) {
            console.log("error", error);
        }
    }

    //clearボタン押下時の処理
    const handleClear = () => {
        setInputUrl("");
    }

    //コースカテゴリ選択
    const handleSelectCourse = (e: SelectChangeEvent<{ value: unknown }>) => {
        if (course !== undefined) {
            setCourse({
                ...course,
                courseCategoryCd: e.target.value as string
            })
        }
    }

    return (
        <CustomDialogFullScreen
            open={props.open}
            onClose={props.handleClose}
        >

            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                コースの新規追加
                            </Typography>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            <Box sx={{ bgcolor: '#DCDCDC', height: "100%" }}>
                <Notice />
                <Grid container justifyContent="center" sx={{ pt: 2 }}>
                    <Grid item xs={12} md={8} >
                        <Paper elevation={3} sx={{ mb: 12 }}>
                            <CardContent>
                                <List>
                                    <ListItem >
                                        <Paper
                                            elevation={0}
                                            variant="outlined"
                                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1, color: 'black' }}
                                                placeholder="URLを入力して下さい"
                                                onChange={(e) => setInputUrl(e.target.value)}
                                                value={inputUrl}
                                                fullWidth
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="clear" onClick={handleClear}>
                                                <ClearIcon />
                                            </IconButton>
                                            <Divider sx={{ height: 35, m: 1 }} orientation="vertical" />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClickSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </ListItem>

                                    <ListItem sx={{ fontSize: "12px", pl: "20px", color: "#C0C0C0" }}>
                                        <div>形式は https://www.udemy.com/course/xxxxxx/ </div>
                                        <div>　 またはUdemy委員会の人はhttps://skigjp.udemy.com/course/xxxxxxx です</div>
                                    </ListItem>


                                    {course &&
                                        <div >
                                            <Divider sx={{ my: 1 }} />
                                            <Grid container
                                                direction="row"
                                                spacing={3}
                                                alignItems="center"
                                                justifyContent="flex-start"
                                            >
                                                <Grid item xs={10} md={5}>

                                                    <FormControl fullWidth sx={{ ml: 2, mt: 2 }}>
                                                        <InputLabel id="demo-simple-select-label">コースカテゴリ</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="コースカテゴリ"
                                                            onChange={handleSelectCourse}
                                                            required={true}
                                                        >
                                                            {couseCateogry?.map((rec) => {
                                                                return (
                                                                    <MenuItem value={rec.cd}>{rec.nameShort}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>

                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={10} md={5} sx={{ ml: 2, mt: 2 }}>
                                                    <Button variant="contained" startIcon={<Send />} disabled={isDisableRegBtn} onClick={handleClickAdd}>コース新規登録</Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }

                                </List>
                            </CardContent>
                        </Paper>

                        {course &&
                            <UdemyCourseDetail {...course} />
                        }

                    </Grid>
                </Grid>
            </Box>

            <Progress isLoading={isLoading} />

        </CustomDialogFullScreen >

    );
});
export default AddCourse;

