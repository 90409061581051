import React, { FC } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { Box, AppBar, Button, IconButton, List, ListItem, Toolbar, Typography, Grid, Paper, CardContent, MenuItem, TextField, Icon, Divider, Alert, InputAdornment } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import CustomDialogFullScreen from '../atom/CustomDialogFullScreen';
import useRing from '../hooks/useRingi';
import Progress from '../core/Progress';
import UdemyLinkButton from '../atom/UdemyLink';
import { Notice } from '../layouts/Notice';
import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';

interface CreateRingiProps {
    courseId: string,
    title: string
    linkUrl: string,
    open: boolean,
    handleClose: () => void
}

const CreateRingi: FC<CreateRingiProps> = React.memo(props => {

    const { ringiSakiList, sendRingi, isLoading } = useRing(props.courseId);

    //Formの値を保持するstate
    const [formValue, setFormValue] = React.useState({
        plMemberId: '',
        projectName: '',
        lectureFrom: '',
        lectureTo: '',
        price: 0
    })

    //稟議書送信ボタン押下時の処理
    const handleClickSend = async () => {
        try {
            const currentUserAttributes: FetchUserAttributesOutput = await fetchUserAttributes();

            await sendRingi(
                { ...formValue, 
                courseId: props.courseId ,
                ringiEntryMemberEmail:currentUserAttributes.email,
                ringiEntryMemberName:currentUserAttributes.name
            });
        } catch (error) {
            console.log("error", error);
        }
    }
    //文字入力の度にstate更新
    const handleChange = (e: any) => {
        const target = e.target;
        const name = target.name;
        setFormValue({ ...formValue, [name]: target.value });
    };

    dayjs.locale('ja') // 日本語に設定

    return (

        <CustomDialogFullScreen open={props.open} onClose={props.handleClose}>

            <LocalizationProvider dateAdapter={AdapterDayjs} locale="ja">

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Typography variant="h5" component="div">
                                    稟議書作成
                                </Typography>
                            </Grid>
                        </Grid>                    </Toolbar>
                </AppBar>

                <Box sx={{ bgcolor: '#DCDCDC', height: "100%" }}>
                    <Notice />

                    <Grid container justifyContent="center" sx={{ pt: 2 }}>
                        <Grid item xs={12} md={8}>

                            <Paper elevation={3} sx={{ mb: 3 }}>

                                <CardContent>
                                    <List>
                                        <ListItem >
                                            <TextField
                                                id="plMemberId"
                                                select
                                                required
                                                label="稟議申請先"
                                                sx={{ width: 600 }}
                                                name='plMemberId'
                                                InputProps={{
                                                    startAdornment: (
                                                        <Icon><AccountCircle /></Icon>
                                                    )
                                                }}
                                                onChange={handleChange}
                                            >
                                                {ringiSakiList && ringiSakiList.map((ringiSaki) =>
                                                    <MenuItem key={ringiSaki.memberId} value={ringiSaki.memberId}>
                                                        {ringiSaki.memberName}
                                                    </MenuItem>
                                                )}

                                            </TextField>

                                        </ListItem>

                                        <ListItem>
                                            <DatePicker
                                                label="受講開始日"
                                                onChange={(event: any) => {
                                                    setFormValue((prevVal) => ({ ...prevVal, lectureFrom: event }))
                                                }}
                                            />

                                            <Divider orientation="vertical" flexItem sx={{ p: 1 }} />

                                            <DatePicker
                                                label="受講終了日"
                                                onChange={(event: any) => {
                                                    setFormValue((prevVal) => ({ ...prevVal, lectureTo: event }))
                                                }}

                                            />
                                        </ListItem>
                                        <ListItem>
                                            <TextField
                                                required
                                                label="プロジェクト名"
                                                sx={{ width: 600 }}
                                                name='projectName'
                                                onChange={handleChange}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <Alert severity="warning">金額はUdemyサイトにて最新の価格を確認して入力下さい<UdemyLinkButton linkUrl={props.linkUrl} /></Alert>
                                        </ListItem>
                                        <ListItem>
                                            <TextField
                                                required
                                                label="金額"
                                                sx={{ width: 600 }}
                                                name='price'
                                                helperText="変動価格のため申請時は割引前の金額を入力して下さい (カンマ無し数字のみ)"
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">￥</InputAdornment>,
                                                }}
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <Button variant="contained" startIcon={<Send />} onClick={handleClickSend}>稟議書送信</Button>
                                        </ListItem>
                                        <ListItem>
                                            <ul>
                                                <li>1.稟議書はメールで送信され、CCに申請者のメールアドレスが設定されます</li>
                                                <li>2.Udemyアカウントが必要です。<span style={{color:"red"}}>事前にskigメールでアカウント作成</span>してから申請して下さい</li>
                                            </ul>                                            
                                        </ListItem>

                                    </List>
                                </CardContent>

                            </Paper>

                        </Grid>
                    </Grid>

                </Box>

                <Progress isLoading={isLoading} />
            </LocalizationProvider>
        </CustomDialogFullScreen >

    );
});
export default CreateRingi;
